import React from 'react'
import { StoreProvider } from 'easy-peasy'
import { Provider } from 'react-redux'
import store from './configureStore'
import Routes from './Routes'
import * as Sentry from '@sentry/react'
import { generatePseudoClass } from 'DesignSystem'
import { hot } from 'react-hot-loader'

import './App.less'

require('dotenv').config()
if (process.env.NODE_ENV !== 'development')
  Sentry.init({ dsn: 'https://b4e9e9232f1543e0a19a843858a6e119@sentry.ficus.ai/3' })

generatePseudoClass()

const App = () => {
  return (
    <StoreProvider store={store}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </StoreProvider>
  )
}

export default hot(module)(App)
